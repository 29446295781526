// get the config from the backend
// list with the enabled components
const features = {
  cheqly: [
    'login',
    'otp',
    'dashboard',
    'accounts',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    // 'communications',
    'settings',
    'recipients',
    'registration',
    'requestResetPassword',
    'cards',
    'cardLimits',
    'forgotPassword',
    // 'users',
    // 'support',
    'payment-types:internal,swift,wire,ach',
    'bottomNavigations',
    'termsConditions'
  ],
  qorbis: [
    'login',
    'otp',
    'dashboard',
    'accounts',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    // 'communications',
    'settings',
    'recipients',
    'employees',
    'employee',
    'registration',
    'requestResetPassword',
    'cards',
    'cardLimits',
    'forgotPassword',
    // 'users',
    // 'support',
    'payment-types:internal,swift,wire,ach',
    'bottomNavigations',
    'termsConditions'
  ],
  prime: [
    'login',
    'otp',
    'dashboard',
    'accounts',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    // 'communications',
    'settings',
    'recipients',
    // 'registration',
    'requestResetPassword',
    // 'cards',
    'forgotPassword',
    // 'users',
    // 'support'
    'payment-types:swift,wire',
    'termsConditions'
  ],
  default: [
    'login',
    'otp',
    'dashboard',
    'accounts',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    // 'communications',
    'settings',
    'recipients',
    'employees',
    'employee',
    'registration',
    'requestResetPassword',
    'cards',
    'forgotPassword',
    // 'users',
    'cardLimits',
    // 'support',
    'payment-types:internal,swift,wire,ach',
    'bottomNavigations',
    'termsConditions'
  ]
}

const client = process.env.VUE_APP_CLIENT

const enabled = (client = '') => {
  if (!client || !features[client]) {
    return features.default
  }

  return features[client]
}

export default enabled(client)
