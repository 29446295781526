// lazyLoads a view or a component
function lazyLoad (path) {
  return () => import(/* webpackChunkName: "[request]" */ '@/' + path + '.vue')
    .catch((error) => {
      if(process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(`Failed to import ${path}.vue`)
        // eslint-disable-next-line no-console
        console.error(error)
      } else {
        // eslint-disable-next-line no-console
        console.error(`Failed to import Vue component`)
      }

      return import('@/components/Shared/FailedImport.vue')
    })
}

export default lazyLoad
