import { getClientSpecificFile } from '@/utils/clientSpecificHelpers'

const enabled = (enabledComponents) => {
  if (!enabledComponents) return () => { return true }

  return (component) => {
    return enabledComponents.includes(component)
  }
}

const AppConfig = {
  install (Vue, options) {
    const { enabledComponents } = options

    Vue.prototype.$appConfig = {
      assets: (file) => getClientSpecificFile(file, 'assets'),
      enabled: enabled(enabledComponents),
      enabledComponents
    }
  }
}

export default AppConfig
