import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules/index.js'
import VuexPersistence from 'vuex-persist'

import { SecureLocalStorageService } from '@/services/SecureLocalStorageService'
const secureLocalStorage = new SecureLocalStorageService()

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: secureLocalStorage
})

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules,
  strict: debug,
  plugins: [vuexLocal.plugin]
})
