/* eslint-disable camelcase */
import { listTickets as getTickets } from '@/api/zendesk'

const getDefaultState = () => {
  return {
    tickets: [],
    isZendeskUnauthenicated: false
  }
}

const state = getDefaultState()

const getters = {
  tickets: (state) => state.tickets
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  fetchTickets (state, tickets) {
    state.tickets = tickets
  },
  checkZendeskAuthenticate (state, { isZendeskUnauthenicated }) {
    state.isZendeskUnauthenicated = isZendeskUnauthenicated
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async fetchTickets ({ commit, rootGetters }) {
    const email = rootGetters['user/email']
    const tickets = await getTickets(email)
    commit('fetchTickets', tickets)
  },
  async checkZendeskAuthenticate ({ commit }, data) {
    commit('checkZendeskAuthenticate', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
