const savingsTransaction = `
  fragment savingsTransaction on SavingsAccountTransaction {
    account {
      id
      currency {
        code
      }
    }
    amount
    createdAt
    typeOf
    paymentDetail {
      reference
    }
  }
`

const subsidiaryAccountTransaction = `
  fragment subsidiaryAccountTransaction on SubsidiaryAccountTransaction {
    account {
      id
      currency {
        code
      }
    }
    amount
    createdAt
    typeOf
    paymentDetail {
      reference
      cardAuthorization {
        id
        merchant
        amountDetails {
          cashBackAmount
          exchangeRate
          feeAmount
          localCurrency
          localCurrencyAmount
        }
      }
      paymentType {
        name
      }
    }
  }
`

const savingsTransactionDetails = `
  fragment savingsTransactionDetails on SavingsAccountTransaction {
    account {
      accountNumber
    }
    paymentDetail {
      creditor {
        name
        identifier
        address
        country
        city
        postalCode
      }
      debtor {
        name
        identifier
        address
        country
        city
        postalCode
      }
      cardAuthorization {
        merchant
        amountDetails {
          cashBackAmount
          exchangeRate
          feeAmount
          localCurrency
          localCurrencyAmount
        }
      }
      paymentType {
        name
      }
    }
  }
`

const subsidiaryAccountTransactionDetails = `
  fragment savingsTransactionDetails on SubsidiaryAccountTransaction {
    paymentDetail {
      cardAuthorization {
        merchant
        amountDetails {
          cashBackAmount
          exchangeRate
          feeAmount
          localCurrency
          localCurrencyAmount
        }
      }
      paymentType {
        name
      }
    }
  }
`

const loanTransaction = `
fragment loanTransaction on LoanTransaction {
  typeOf
  valueDate
  amount
  outstandingLoanBalance
  penaltyChargesPortion
  feeChargesPortion
  interestPortion
  principalPortion
  loan {
    id
    productDetail {
      currency {
        code
      }
    }
  }
}
`

export {
  savingsTransaction,
  savingsTransactionDetails,
  loanTransaction,
  subsidiaryAccountTransaction,
  subsidiaryAccountTransactionDetails
}
