const clientTranslation = {
  tokenizer: {
    stopRegistrationModal: {
      title: 'Thank you for showing your interest in Tokenizer',
      subtitleOne: 'Your business is not yet registered?',
      textOne: 'If you have not registered your business yet, we require your business to register before continuing.'
    },
    clientCreationNotes: `IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING AN ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens a Bank Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, email address, home address, SSN (SSN or ITIN was not requested during onboarding) and other information that will allow us to identify you. We may also ask to see a copy of your driver's license or other identifying documents.`,
    components: {
      registration: {
        statics: {
          submitFinalConfirmation: {
            description: 'You currently applied for opening an account through our bank app. Within 24 hours, please expect to receive a link to your mobile number provided during registration. The link will request you to upload your Government issued document together with a selfie as part of Tokenizer onboarding process. If you want to confirm this application, please click the link below.'
          }
        }
      }
    }
  }
}

export default clientTranslation
