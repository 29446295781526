import { withAuth } from '@/api/request.js'

const request = withAuth()

export default () => {
  return request({
    data: {
      query: `
        {
          getSubsidiaryAccounts {
            total
            select {
              id
              availableBalance
              accountNo
              status
              parentAccountId
              currency
              designation
              mobileNo
              savingsProductId
              card {
                id
                network
                status
              }
              client {
                firstName
                lastName
                statusData {
                  id
                  code
                  value
                }
              }
              user {
                id
                email
              }
            }
          }
        }
      `
    }
  })
}
