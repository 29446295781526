<template>
  <v-flex>
    <p class="text-center mb-4">
      The application is in ALPHA testing phase
    </p>
  </v-flex>
</template>

<script>
export default {
  name: 'AlphaWarning'
}
</script>
