import { withAuth } from '@/api/request.js'

const request = withAuth()

const getUsersList = ({ page, perPage, orderBy, sortOrder, roleId }) => {
  const roleCondition = roleId ? `,where: {roles: {id: {EQ: ${roleId}}}}` : ''
  let query = `
    {
      AppUsers(page: { start: ${page}, limit: ${perPage} }${roleCondition}) {
        total
        pages
        select {
          id
          username
          lastName
          firstName
          email
          mobile
          roles {
            id
            name
          }
        }
      }
    }
  `
  if (orderBy) query = query.replace(orderBy, `${orderBy} (orderBy: ${sortOrder})`)
  return request({
    data: { query }
  })
}
const getRolesWithUserCount = () => {
  return request({
    data: {
      query: `
        {
          getSSURoles {
            id
            name
            description
            noOfAssignedUsers
          }
        }
      `
    }
  })
}

const getRolesAvailableForUser = () => {
  return request({
    data: {
      query: `
        {
          getUserTemplateForClient {
            availableRoles {
              id
              name
              description
              disabled
            }
          }
        }
      `
    }
  })
}

const createSelfServiceUser = (params = {}) => {
  return request({
    data: {
      query: `mutation {
        createClientUser(
          input: {
            email: "${params.email}"
            firstname: "${params.firstName}"
            lastname: "${params.lastName}"
            mobile: "${params.mobile}"
            roles: ${JSON.stringify(params.roles)}
          }
        )
      }`
    }
  })
}

const updateSelfServiceUserRoles = (id, roles = []) => {
  return request({
    data: {
      query: `mutation {
        updateUserRoles(id: ${id}, 
          input: {
            roles: ${JSON.stringify(roles)}
          }
        )
      }`
    }
  })
}

export {
  getUsersList,
  getRolesWithUserCount,
  getRolesAvailableForUser,
  createSelfServiceUser,
  updateSelfServiceUserRoles
}
