import SecureLS from 'secure-ls'

class SessionStorageSecureLS extends SecureLS { // overriding standard secure-ls to use session storage instead of local storage
  constructor(config) {
    super(config);

    super.ls = sessionStorage;
  }
}

export class SecureLocalStorageService {
  constructor (encryptionSecret = 'SECRET') {
    this.ls = new SessionStorageSecureLS({ encodingType: 'aes', encryptionSecret })
  }

  getItem (key) {
    return this.ls.get(key)
  }

  setItem (key, value) {
    this.ls.set(key, value)
  }

  removeItem (key) {
    this.ls.remove(key)
  }

  clear () {
    this.ls.clear()
  }
}
