const clientTranslation = {
  cheqly: {
    stopRegistrationModal: {
      title: 'Thank you for showing your interest in Cheqly Inc.',
      subtitleOne: 'Your business is not yet registered?',
      textOne: 'If you have not registered your business yet, we can help you set one up with IncParadise.com. IncParadise is our partner helping with company registration. Once you form your company, please come back to finish the account opening. Register company at https://incparadise.net/cheqly/incorporation',
      subtitleTwo: 'Missing TIN/EIN?',
      textTwo: 'If you do not have a TIN/EIN, we can help you set one up with IncParadise.com. IncParadise is our partner helping with EIN in all US states. Once you are assigned an EIN number, please come back to finish the account opening. Get EIN at https://incparadise.net/cheqly/ein-filing'
    },
    clientCreationNotes: `IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING AN ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens a Bank Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver's license or other identifying documents.`,
    views: {
      login: {
        form: {
          fields: {
            username: {
              label: 'User email',
              placeholder: 'john@email.com'
            }
          }
        },
        forgotPassword: {
          request: {
            description: 'Enter your user email used to login to web banking',
            user: 'Enter your user email'
          }
        }
      }
    }
  }
}

export default clientTranslation
