<template>
  <v-card>
    <v-card-title>
      <div class="text-h5 primary--text font-weight-bold mb-4">
        {{ $t(`clientSpecificTranslations.${client}.stopRegistrationModal.title`) }}
      </div>
    </v-card-title>
    <v-card-text>
      <p class="subtitle-2">
        {{ $t(`clientSpecificTranslations.${client}.stopRegistrationModal.subtitleOne`) }}
      </p>
      <p>
        {{ $t(`clientSpecificTranslations.${client}.stopRegistrationModal.textOne`) }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import stopRegistrationMixin from '@/mixins/stopRegistrationMixin'

export default {
  name: 'MbanqStopRegistrationDetail',
  mixins: [stopRegistrationMixin]
}
</script>
