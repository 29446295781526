import { withAuth } from '@/api/request.js'
const request = withAuth()

export default () => {
  return request({
    data: {
      query: `{
        Organizations {
            select {
                id,
                name,
                emailAddress,
                address,
                about
            }
        }
    }`
    }
  })
}
