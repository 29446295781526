import { restApi } from '@/api/request.js'
import { getTenantId } from '@/utils/helpers'

const request = restApi()

const downloadAccountReport = (reportType, accountId, dateFormat = 'MMMM dd yyyy') => {
  return request({
    method: 'get',
    url: `/self/runreports/${reportType}?output-type=PDF&tenantIdentifier=${getTenantId()}&locale=en&dateFormat=${dateFormat}&R_savingsId=${accountId}`,
    responseType: 'blob'
  })
}

const downloadAccountStatement = (accountId, startDate, endDate, dateFormat = 'MMMM dd yyyy') => {
  const reportType = 'Report current and saving account(Pentaho)'
  return request({
    method: 'get',
    url: `/self/runreports/${reportType}?output-type=PDF&tenantIdentifier=${getTenantId()}&locale=en&dateFormat=${dateFormat}&R_saving_no=${accountId}&R_start_date=${startDate}&R_end_date=${endDate}`,
    responseType: 'blob'
  })
}

export { downloadAccountReport, downloadAccountStatement }
