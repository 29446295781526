import getInitialData from '@/api/views/initialData.js'
import getDashboardData from '@/api/views/dashboard.js'
import getAccountListData from '@/api/views/accounts/accountList.js'
import getCurrentAccountDetailsData from '@/api/views/accounts/currentAccountDetails.js'
import getLoanAccountDetailsData from '@/api/views/accounts/loanAccountDetails.js'
import getTransactionListData from '@/api/views/transactions/transactionList.js'
import getTransactionDetailsData from '@/api/views/transactions/transactionDetails.js'
import getCommunicationListData from '@/api/views/communications.js'
import getCompanyInformation from '@/api/views/organization.js'
import { cardsList as getCardsListData } from '@/api/views/cards/index.js'
import getRecipientListData from '@/api/views/recipients/recipientList.js'
import getRecipientDetailsData from '@/api/views/recipients/recipientDetails.js'
import getEmployeeListData from '@/api/views/employees/employeeList.js'
import {employeeSubsidiaryAccountsAdapter} from '@/api/adapters/employees/employeeSubsidiaryAccountsAdapter'

const actions = {
  async initialLoading ({ commit, dispatch, rootGetters, rootState }) {
    const currentDate = new Date()
    const beginningLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    const username = rootState.user.username
    await dispatch('views/organization', null, { root: true })
    const response = await getInitialData({ beginningLastMonth, currentDate, username })
    const user = {
      appUser: response.data.data.AppUsers.select[0],
      client: response.data.data.Clients.select[0]
    }
    commit('user/update', user, { root: true })
    commit('accounts/updateSavings', response.data.data.SavingsAccounts.select, { root: true })
    if (rootGetters['accounts/activeSavings'].length <= 0) {
      throw new Error('noActiveSavings')
    }
    commit('transactions/updateTransactions', { txs: response.data.data.SavingsAccountTransactions.tx, type: 'savings' }, { root: true })

    await dispatch('views/dashboard', null, { root: true })
    await dispatch('preferences/fetchAccountNumberFormat', null, { root: true })
  },
  async dashboard ({ commit, rootGetters }) {
    const savingsIds = rootGetters['accounts/list']('savings').map(acc => acc.id)
    const response = await getDashboardData({ savingsIds })

    commit('accounts/updateSavings', response.data.data.SavingsAccounts.select, { root: true })
    commit('application/updateActiveCurrency', response.data.data.SavingsAccounts.select[0].currency.code, { root: true })
    let newTx = []
    savingsIds.forEach(id => {
      const latestTx = response.data.data[`latestTxSavings${id}`]?.select || []
      const pagedTx = response.data.data[`pagedTxSavings${id}`]?.select || []
      newTx = newTx.concat(latestTx).concat(pagedTx)
    })
    commit('transactions/updateTransactions', { txs: newTx, type: 'savings' }, { root: true })
    commit('transactions/updateHoldTx', response.data.data.AuthorizeTransactions.select, { root: true })
    commit('cards/setState', response.data.data.Cards.select, { root: true })
  },
  async organization ({ commit }) {
    const {
      data: {
        data: {
          Organizations: {
            select: [organization]
          }
        }
      }
    } = await getCompanyInformation()
    commit('organization/update', organization, { root: true })
  },
  async accountList ({ commit }) {
    const response = await getAccountListData()

    commit('accounts/updateSavings', response.data.data.SavingsAccounts.select, { root: true })
    commit('accounts/updateLoan', response.data.data.Loans.select, { root: true })
  },
  async currentAccountDetails ({ commit }, id) {
    const response = await getCurrentAccountDetailsData(id)

    commit('accounts/updateSavingsDetails', response.data.data.SavingsAccount, { root: true })
    commit('transactions/updateTransactions', { txs: response.data.data.SavingsAccountTransactions.tx, type: 'savings' }, { root: true })
    commit('transactions/updateHoldTx', response.data.data.AuthorizeTransactions.select, { root: true })
  },
  async loanAccountDetails ({ commit }, loanId) {
    const response = await getLoanAccountDetailsData(loanId)

    commit('accounts/updateLoanDetails', response.data.data.Loan, { root: true })
    commit('transactions/updateTransactions', { txs: response.data.data.LoanTransactions.select, type: 'loan' }, { root: true })
  },
  async transactionList ({ commit }, { accounts, page, limit }) {
    const { data: { data } } = await getTransactionListData({ accounts, page, limit })

    commit('accounts/updateSavings', data.SavingsAccounts.select, { root: true })
    commit('accounts/updateTotalTx', data, { root: true })

    let newTx = []
    accounts.forEach(id => {
      const latestTx = data[`latestTxSavings${id}`]?.select || []
      const pagedTx = data[`pagedTxSavings${id}`]?.select || []

      newTx = newTx.concat(latestTx).concat(pagedTx)
    })
    commit('transactions/updateTransactions', { txs: newTx, type: 'savings' }, { root: true })
    commit('transactions/updateHoldTx', data.AuthorizeTransactions.select, { root: true })
  },
  async transactionDetails ({ commit, rootGetters }, id) {
    const { data: { data } } = await getTransactionDetailsData(id)
    const isEmployee = rootGetters['user/isEmployee']

    commit('transactions/updateTransactionDetails', isEmployee ? data.getSubsidiaryAccountTransaction : data.SavingsAccountTransaction, { root: true })
  },
  async communicationList ({ commit }, { page, limit, entityId }) {
    const { data: { data } } = await getCommunicationListData({ page, limit, entityId })

    commit('communications/updateCommunications', data, { root: true })
  },
  async cardsList ({ commit }) {
    const response = await getCardsListData()
    commit('cards/setState', response.data.data.Cards.select, { root: true })
  },
  async recipientList ({ commit }, { page, limit, id }) {
    const response = await getRecipientListData({ page, limit, id })
    const latestItems = response.data.data?.latest?.select || []
    const pagedItems = response.data.data?.paged?.select || []
    const fetchedItems = latestItems.concat(pagedItems)
    const allItemIDs = response.data.data.total.select

    commit('recipients/updateRecipients', { fetchedItems, allItemIDs }, { root: true })
  },
  async recipientDetails ({ commit }, { id, creditorId }) {
    const response = await getRecipientDetailsData({ id, creditorId })

    commit('recipients/updateRecipientDetails', Object.assign(response.data.data.Recipient, response.data.data.Transfers), { root: true })
  },
  async employeesList ({ commit }) {
    const list = employeeSubsidiaryAccountsAdapter(await getEmployeeListData())
    commit('employees/updateEmployees', { list }, { root: true })
  }
}

export default {
  namespaced: true,
  actions
}
