import {
  getAccountNumberFormat
} from '@/api/coreSettings'

const getDefaultState = () => {
  return {
    accountNumberFormat: {
      // Default account number length for internal
      // Internal has no prefix by default if we do not config it to have
      accountNumberLength: 9,
      customPrefix: ''
    }
  }
}

const state = getDefaultState()

const getters = {
  accountNumberPrefix: ({ accountNumberFormat: { customPrefix } }) => customPrefix,
  accountNumberTotalLength: ({ accountNumberFormat: { accountNumberLength, customPrefix } }) => customPrefix.length + accountNumberLength,
  accountNumberFormat: (state) => state.accountNumberFormat
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  fetchAccountNumberFormat (state, format) {
    const customPrefix =  format?.customPrefix || ''

    state.accountNumberFormat = {
      ...state.accountNumberFormat,
      ...format,
      customPrefix
    }
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async fetchAccountNumberFormat ({ commit }) {
    const {
      data: {
        data: {
          AccountNumberFormats: {
            select: [
              format
            ]
          }
        }
      }
    } = await getAccountNumberFormat()
    commit('fetchAccountNumberFormat', format)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
