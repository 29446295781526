import i18n from '@/plugins/i18n.js'

const clientPublicDomain = 'https://public.app.cheqly.com'

const termAndConditions = [
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.termsOfService'),
    link: 'https://cheqly.com/us/terms-of-service/'
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.privacyPolicy'),
    link: 'https://cheqly.com/us/privacy-policy/'
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.accountCardAgreement'),
    link: `${clientPublicDomain}/evolve-business-account-and-cardholder-agreement.pdf`
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.electronicCommunicationsAgreement'),
    link: `${clientPublicDomain}/electronic-communication-consent.pdf`
  }
]

export default termAndConditions
