import router from '@/router/index.js'
import {
  requestResetPassword,
  resetPassword,
  changePassword,
  getUserDetails,
  getAuthenticationDetail,
  acceptTermsConditons as acceptTermsAndConditionsAPI
} from '@/api/user.js'
import {
  auth,
  validateOTP,
  requestOTP,
  enableTOTP,
  confirmTOTP,
  disableTOTP
} from '@/api/auth.js'
import { clearLocalStorage, permissionsArePresent } from '@/utils/helpers.js'
import EventBus from '@/utils/eventBus.js'
import i18n from '@/plugins/i18n'
import { restApi } from '@/api/request'
import { SecureLocalStorageService } from '@/services/SecureLocalStorageService'
const secureLocalStorage = new SecureLocalStorageService()

const getDefaultState = () => {
  return {
    locale: 'en',
    dateFormat: 'yyyy-MM-dd',
    authenticated: false,
    required2FA: false,
    isPasswordExpired: false,
    isRequireAcceptTermsConditions: false,
    appUser: {},
    username: '',
    details: {
      getAuthenticationDetail: {
        mfaDeliveryMethods: []
      }
    }
  }
}

const state = getDefaultState()

const getters = {
  get: state => state,
  id: state => state.appUser.id,
  fullName: state => state.client?.displayName,
  userFullName: state => `${state.appUser.firstName} ${state.appUser.lastName}`,
  organizationName: (state, getters) => state.client?.parent ? state.client.parent.displayName : (getters.isLegalEntity ? state.client.displayName : ''),
  isEmployee: () => false,
  email: state => state.appUser.email,
  user: state => state.appUser,
  isTotpEnabled: state => state.details?.getAuthenticationDetail?.mfaDeliveryMethods?.find(method => method === 'TOTP'),
  permissions: state => state.appUser.roles?.map(role => role.rolePermissions.map(permission => {
    return { code: permission.permission.code, full: permission.hasFullAccess }
  })).flat(),
  userHasPermissions: (state, getters) => (permissions) => permissionsArePresent(permissions, getters.permissions),
  isLegalEntity: state => state.client?.legalForm === 2
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  update (state, payload) {
    Object.assign(state, payload)
  },
  updateDetails (state, payload) {
    Object.assign(state, {
      ...state,
      details: {
        ...state.details,
        ...payload
      }
    })
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  update (context, payload) {
    context.commit('update', payload)
  },
  async login ({ commit, dispatch, state }, credentials) {
    await auth(credentials)
    commit('update', { username: credentials.username })

    const isRequiredResetPassword = secureLocalStorage.getItem('required_password_reset') === true
    const isRequireAcceptTermsConditions = secureLocalStorage.getItem('required_terms_and_conditions_check') === true


    if (isRequiredResetPassword) {
      router.push('/request-reset-password')
    } else if (isRequireAcceptTermsConditions) {
      commit('update', { isRequireAcceptTermsConditions: true })
      return router.push('/terms-conditions')
    } else {

      try {
        await dispatch('updateUserDetails')
      } catch (e) {
        EventBus.$emit('notify-with-message', e?.message)
        if (e.message === 'Your password has expired and must be changed to continue') {
          router.push('/request-reset-password')
          return
        }
      }

      // If there is no client attached to the user do not allow user to proceed
      if (!state.details.Client.length) {
        EventBus.$emit('notify-with-message', i18n.t('views.error.noAttachedClient'))
        return
      }

      const isUnverified = await dispatch('checkUnverifiedClient')

      if (router.currentRoute.name === 'registration') return

      await dispatch('checkSelfServiceUser')

      if (await dispatch('checkMFA', isUnverified ? { query: { src: 'unverified' } } : {})) return

      if (isUnverified) return router.push('/registration')

      // checkMFA already push to otp if needed
      if (router.currentRoute.name === 'otp') return

      await dispatch('checkExpiredPassword')
      await dispatch('application/toggleLoadingScreen', '', { root: true })

      commit('update', { authenticated: true })
    }
  },
  async acceptTermsConditons ({ commit, dispatch }) {
    try {
      await acceptTermsAndConditionsAPI()
    } catch {
      EventBus.$emit('notify-with-message', 'Unable to accept term and condition')
    }

    commit('update', { isRequireAcceptTermsConditions: false })
    await dispatch('checkExpiredPassword')
    await dispatch('application/toggleLoadingScreen', '', { root: true })
    commit('update', { authenticated: true })
  },
  async updateUserDetails ({ commit }) {
    const { data: { data: newDetail, errors } } = (await getUserDetails())
    if (errors?.length) {
      throw Error(errors[0].message)
    }

    commit('updateDetails', {
      ...newDetail,
      PasswordValidationPolicies: newDetail.PasswordValidationPolicies.select
    })
  },
  async checkSelfServiceUser ({ state }) {
    const { isSelfServiceUser } = state.details.getAuthenticationDetail

    if (!isSelfServiceUser) {
      throw new Error('noPermissions')
    }
  },
  async checkUnverifiedClient ({ state }) {
    const client = state.details.Client

    if (client[0].status === '100') { // 100 = Unverified client
      secureLocalStorage.setItem('clientId', client[0].id)
      return true
    }
    return false
  },
  async checkMFA ({ state, commit, getters }, { query } = {}) {
    const { isMFARequired } = state.details.getAuthenticationDetail

    if (!isMFARequired) return

    if (!getters.isTotpEnabled) requestOTP()

    commit('update', { required2FA: true })

    await router.push({ name: 'otp', query })

    return true
  },
  async checkExpiredPassword ({ state, commit }) {
    const { isPasswordExpired } = state.details.getAuthenticationDetail

    if (!isPasswordExpired) return

    commit('update', { isPasswordExpired: true })
    return router.push('/reset-password')
  },
  async logout ({ dispatch }) {
    const invalidateToken = restApi({
      baseURL: `${process.env.VUE_APP_API_URL}/oauth/invalidate`,
      method: 'post'
    })

    try {
      await invalidateToken()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Invalid token will be logging off.')
    }

    await dispatch('application/resetState', '', { root: true })

    if (typeof window.zE?.hide === 'function') window.zE.hide()
    clearLocalStorage()
    await router.push({ name: 'login' })
  },
  async submitOtp ({ state, commit, dispatch }, otp) {
    const twoFA = await validateOTP(otp)
    if (twoFA.data.errors) {
      throw twoFA.data.errors
    }
    secureLocalStorage.setItem('2FA-Token', twoFA.data.data.requestAccessTokenFromOTP.token)
    const isPasswordExpired = state.details.getAuthenticationDetail.isPasswordExpired

    if (isPasswordExpired) {
      commit('update', { isPasswordExpired: true })
      return router.push('/reset-password')
    }

    const src = router.currentRoute.query?.src

    if (src === 'registration') await dispatch('registration/goNext', {}, { root: true })

    if (['registration', 'unverified'].includes(src)) {
      await router.push('/registration')
      return
    }

    await dispatch('application/toggleLoadingScreen', '', { root: true })

    commit('update', { authenticated: true })
  },
  async requestResetPassword (context, identifier) {
    const result = await requestResetPassword(identifier)
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async resetPassword (context, payload) {
    const result = await resetPassword(payload)
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async submitPasswordChanges ({ state }, password) {
    const result = await changePassword({ identifier: state.appUser.username, ...password })
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async updateAuthenticationDetail ({ commit }) {
    const newDetail = (await getAuthenticationDetail()).data.data
    commit('updateDetails', newDetail)
  },
  async enableTOTP () {
    return enableTOTP()
  },
  async disableTOTP ({ state, dispatch }, payload) {
    const dataTOTP = await disableTOTP(payload)

    if (dataTOTP.data.data === null) {
      throw new Error(dataTOTP.data.errors[0].message)
    }

    if (!state.details.getAuthenticationDetail.isMFARequired) {
      secureLocalStorage.removeItem('2FA-Token')
    }

    await dispatch('updateAuthenticationDetail')
  },
  async confirmEnableTOTP (_, payload) {
    const dataTOTP = await confirmTOTP(payload)

    if (!dataTOTP.data.data?.confirmTOTP) {
      throw new Error(dataTOTP.data.errors[0].message)
    }

    const { mfaToken, success, recoveryCodes } = dataTOTP.data.data.confirmTOTP

    if (dataTOTP.data.data.confirmTOTP.mfaToken) {
      secureLocalStorage.setItem('2FA-Token', mfaToken.token)
    }

    return { success, recoveryCodes }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
