const clientTranslation = {
  payhammer: {
    stopRegistrationModal: {
      title: 'Thank you for showing your interest in Payhammer',
      subtitleOne: 'Your business is not yet registered?',
      textOne: 'If you have not registered your business yet, we require your business to register before continuing.'
    }
  }
}

export default clientTranslation
