import i18n from '@/plugins/i18n.js'

const clientPublicDomain = 'https://public.dev.mbanq.cloud'

const termAndConditions = [
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.termsOfService'),
    link: `${clientPublicDomain}/terms-of-service.pdf`
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.privacyPolicy'),
    link: `${clientPublicDomain}/privacy-policy.pdf`
  },
  {
    displayText: i18n.t('components.about.contactUs'),
    link: 'https://www.mbanq.com/contact/'
  }
]

export default termAndConditions
