import { withAuth } from '@/api/request.js'
import { addDays } from '@/utils/helpers.js'
import { savingsTransaction, savingsTransactionDetails, subsidiaryAccountTransaction, subsidiaryAccountTransactionDetails } from '@/api/fragments/index.js'
import store from '@/store'

const request = withAuth()

export default ({ accounts, filters }) => {

  const isEmployee = store.getters['user/isEmployee']

  if(isEmployee) {
    return request({
      data: {
        query: `
        {
          result: getSubsidiaryAccountTransactions (
            where: {${dateFilter(filters, true)} ${employeeSearchQuery(filters)}}
          ) {
            tx: select {
              id
              ...subsidiaryAccountTransaction
              ...savingsTransactionDetails
            }
            total
          }
        }
        ${subsidiaryAccountTransaction}
        ${subsidiaryAccountTransactionDetails}
      `
      }
    })
  }

  return request({
    data: {
      query: `
        {
          result: SavingsAccountTransactions (
            where: {${dateFilter(filters)} ${savingsFilter(accounts, filters)}}
          ) {
            tx: select {
              id (orderBy: DESC)
              ...savingsTransaction
              ...savingsTransactionDetails
            }
            pages
            total
          }
        }
        ${savingsTransaction}
        ${savingsTransactionDetails}
      `
    }
  })
}

const savingsSearchQuery = (keyword) => {
  if (!isNaN(keyword)) {
    return `amount: {EQ: "${keyword}"} paymentDetail: {reference: {LIKE_: "${keyword}"}}`
  }

  const keywords = keyword.split(' ')
  let reference = ''
  let amount = ''
  keywords.forEach(word => {
    if (!isNaN(word)) {
      amount += ` amount: {EQ: "${word}"} `
    }
    reference += `{reference: {LIKE_: "${word}"}}`
  })
  return `${amount} paymentDetail:{OR:[${reference}]}`
}

const savingsFilter = (accounts, filters = { keywords: '' }) => {
  let filter = ''
  accounts.forEach(account => {
    filter += `id: {EQ: ${account}} `
  })
  if (filters.keywords) {
    return `
      account: {
        OR: [ { ${filter} } ]
      }
      OR: [{
        ${savingsSearchQuery(filters.keywords)}
      }]`
  } else {
    return `account: {
        OR: [ { ${filter} } ]
      }`
  }
}

const employeeSearchQuery = (filters) => {
  const keyword = filters.keywords
  if(!keyword) return ''
  if (isNaN(keyword)) { return ` reference: "${keyword}"` }
  return ` amount: ${keyword}`
}

const dateFilter = (filters = '', isEmployee = false) => {
  if (!filters.fromDate && !filters.toDate) return ''

  const fromDate = new Date(filters.fromDate).toISOString().replace('Z', '')
  const toDate = new Date(filters.toDate).toISOString().replace('Z', '')

  if (filters.fromDate && !filters.toDate) {
    return `createdAt: {GE:${JSON.stringify(fromDate)}}`
  }

  if (!filters.fromDate && filters.toDate) {
    return `createdAt: {LE:${JSON.stringify(addDays(toDate, 1))}}`
  }

  if(isEmployee) {
    return `createdAt: {LE:${JSON.stringify(addDays(toDate, 1))} GE:${JSON.stringify(fromDate)}}`
  }

  return `createdAt: {BETWEEN:${JSON.stringify([fromDate, addDays(toDate, 1)])}}`
}
