import { withAuth } from '@/api/request.js'

const request = withAuth()

export default ({ page, limit, entityId }) => {
  return request({
    data: {
      query: `
        {
          Medias(where: { type: {EQ: "statement"}, entityId: {EQ: ${entityId} } } page:{ start:${page} limit: ${limit} }) {
            total
            select {
              type
              id
              name
              description
              filename
              entityType
              entityId
              createdAt (orderBy: DESC)
            }
          }      
        }
      `
    }
  })
}
