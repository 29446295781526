import store from '../../store/index.js'
import lazyLoad from '@/utils/lazyLoad.js'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: lazyLoad('views/Dashboard')
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: lazyLoad('views/Accounts/List')
  },
  {
    path: '/accounts/loan/:id',
    name: 'loanAccount',
    component: lazyLoad('views/Accounts/Loan')
  },
  {
    path: '/accounts/current/:id',
    name: 'currentAccount',
    component: lazyLoad('views/Accounts/Current')
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: lazyLoad('views/Transactions')
  },
  {
    path: '/transfers/new',
    name: 'newTransfer',
    meta: { permissions: ['CREATE_TRANSFER', 'EXECUTE_TRANSFER'] },
    component: lazyLoad('views/Transfers/New'),
    beforeEnter: (_to, from, next) => {
      if (!store.getters['user/isEmployee']) return next()
      return next(from.path)
    }
  },
  {
    path: '/support',
    name: 'support',
    component: lazyLoad('views/Support'),
    beforeEnter: (_to, from, next) => {
      if (store.state.support.tickets.length) return next()
      return next(from.path)
    }
  },
  {
    path: '/cards',
    name: 'cards',
    component: lazyLoad('views/Cards'),
    beforeEnter: (_to, from, next) => {
      if (store.getters['cards/hasCards']) return next()
      return next(from.path)
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: lazyLoad('views/Settings')
  },
  {
    path: '/recipients',
    name: 'recipients',
    meta: { permissions: ['READ_BENEFICIARY'] },
    component: lazyLoad('views/Recipients'),
    beforeEnter: (_to, from, next) => {
      if (!store.getters['user/isEmployee']) return next()
      return next(from.path)
    }
  },
  {
    path: '/users',
    name: 'users',
    component: lazyLoad('views/Users'),
    meta: { permissions: [{ code: 'READ_APPUSER', full: true }] },
    beforeEnter: (_to, from, next) => {
      if (store.getters['user/isLegalEntity']) return next()
      return next(from.path)
    }
  },
  {
    path: '/employees',
    name: 'employees',
    meta: { permissions: ['READ_SUBSIDIARY_ACCOUNT'] },
    component: lazyLoad('views/Employees/Employees'),
    beforeEnter: (_to, from, next) => {
      if (store.getters['user/isLegalEntity']) return next()
      return next(from.path)
    }
  },
  {
    path: '/employees/:primaryAccountId/:employeeId',
    name: 'employee',
    meta: { permissions: ['READ_SUBSIDIARY_ACCOUNT'] },
    component: lazyLoad('views/Employees/Employee'),
    beforeEnter: (_to, from, next) => {
      if (store.getters['user/isLegalEntity']) return next()
      return next(from.path)
    }
  }
]

export default routes.map(route => {
  const meta = {
    ...route.meta,
    public: false
  }
  return { ...route, meta }
})
