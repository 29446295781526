import colors from 'vuetify/es5/util/colors.js'

const dark = {
  primary: '#1569FF',
  secondary: colors.teal.base,
  tertiary: colors.red.lighten5,
  quinary: colors.grey.darken1,
  accent: colors.pink.base,
  error: colors.pink.lighten1,
  surface: '#282727', // main components BG
  quaternary: '#515151', // inside main component elem enclosure BG
  surface2: colors.pink.base,
  background: '#090909'
}

const light = {
  primary: '#2e4a9e',
  secondary: colors.teal.base,
  tertiary: colors.red.lighten5,
  quaternary: colors.grey.lighten3,
  quinary: colors.grey.lighten2,
  accent: '#1d1a27',
  error: colors.pink.darken3,
  background: '#fafafa',
  surface: '#ffffff',
  surface2: '#f2f2f2'
}

export {
  dark,
  light
}
