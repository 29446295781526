import { withAuth } from '@/api/request.js'
import store from '@/store/index.js'

const request = withAuth()

const validateRecipient = async (nickname, id) => {
  const recipient = await request({
    data: {
      query: `{
        Beneficiaries(where: {name: { EQ: "${nickname}"}}) {
          select {
            id
          }
        }
      }`
    }
  })
  if (recipient.data.data.Beneficiaries.select.length && recipient.data.data.Beneficiaries.select[0].id !== id) {
    throw new Error('recipient_exist')
  }
}

const createOrUpdateRecipient = (recipient) => {
  const isInternalRecipient = recipient.beneficiary === 'INTERNAL'
  const getInternalIdentifier = `ACCOUNT:${recipient.creditor.accountNo}`
  const getExternalIdentifier = `${recipient.beneficiary}://${recipient.creditor.sortCode}/${recipient.creditor.accountNo}`
  const identifier = isInternalRecipient ? getInternalIdentifier : getExternalIdentifier
  const agent = generateAgentInput(recipient.beneficiary, recipient.creditor)

  if (recipient.id) {
    return request({
      data: {
        query: `mutation {
          UpdateBeneficiary: updateBeneficiary (id: "${recipient.id}" input:{
            name: "${recipient.nickname}"
            email: "${recipient.email}"
            mobile: "${recipient.mobile}"
            paymentType: "${recipient.beneficiary}"
            creditor: {
              name: "${recipient.fullName}"
              identifier: "${identifier}"
              accountType: ${recipient.creditor.accountType}
              accountEntity: PERSONAL
              address: [
                "${recipient.address.address[0]}"
                "${recipient.address.address[1]}"
                "${recipient.address.address[2]}"
              ]
              stateOrProvince: "${recipient.address.stateOrProvince}"
              country: "${recipient.address.country}"
              city: "${recipient.address.city}"
              postalCode: "${recipient.address.postalCode}"
              ${agent}
            }
          }){
            id
          }
        }`
      }
    })
  }
  return request({
    data: {
      query: `mutation {
        CreateRecipient: createBeneficiary (input:{
          name: "${recipient.nickname}"
          email: "${recipient.email || ''}"
          mobile: "${recipient.mobile || ''}"
          paymentType: "${recipient.beneficiary}"
          clientId: ${store.state.user.client.id}
          creditor: {
            name: "${recipient.fullName}"
            identifier: "${identifier}"
            accountType: ${recipient.creditor.accountType}
            accountEntity: PERSONAL
            address: [
              "${recipient.address.address[0]}"
              "${recipient.address.address[1]}"
              "${recipient.address.address[2]}"
            ]
            stateOrProvince: "${recipient.address.stateOrProvince}"
            country: "${recipient.address.country}"
            city: "${recipient.address.city}",
            postalCode: "${recipient.address.postalCode}"
            ${agent}
          }
        }){
          id
        }
      }`
    }
  })
}

const generateAgentInput = (paymentType, creditor) => {
  if (paymentType !== 'SWIFT' || !creditor?.agent) return ''

  return `agent: {
         name: "${creditor.agent.name}"
         country: "${creditor.agent.country}"
         identifier: "${creditor.sortCode}"
         address: [
          "${creditor.agent.address?.[0] || ''}"
          "${creditor.agent.address?.[1] || ''}"
        ]
         stateOrProvince: "${creditor.agent.stateOrProvince}"
         city: "${creditor.agent.city}"
         postalCode: "${creditor.agent.postalCode}"
      }`
}

export {
  createOrUpdateRecipient,
  validateRecipient,
  generateAgentInput
}
