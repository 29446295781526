import {
  noAuth,
  withAuth,
  restApi
} from '@/api/request.js'

const requestNoAuth = noAuth()
const requestWithAuth = withAuth()
const requestRestApi = restApi()

const getConfiguration = () => {
  return requestWithAuth({
    data: {
      query: `{
        getOnBoardingTemplate (entityType: "Registration" ) {
          steps {
            stepId
            title
            description
            position
            isMultiRow
            isDataTable
            resourceIdentifier
            resourcePath
            groupName
            sections {
              id
              title
              description
              position
              parentNameAttribute
            }
          }
        }
      }`
    }
  })
}

const getStepConfiguration = (stepId, clientId) => {
  return requestWithAuth({
    data: {
      query: `{
        getStepDetails (stepId: ${stepId} clientId: ${clientId}) {
          title
          description
          isDataTable
          resourceIdentifier
          resourcePath
          position
          sections {
            id
            title
            description
            position
            parentNameAttribute
          }
          columnHeaders {
            columnName
            columnDisplayType
            columnDisplayName
            columnType
            sectionId
            columnCode
            columnLength
            columnValues {
              value
              id
            }
            isColumnNullable
            isColumnPrimaryKey
            parentNameAttribute
            regex
            hint
            mask
            unmaskedSuffixLength
          }
          data
        }
      }`
    }
  })
}

const getStepConfigurations = (stepIds, clientId) => {
  const fragment = `fragment query on Step{
    title
      description
      isDataTable
      resourceIdentifier
      resourcePath
      position
      sections {
        id
        title
        description
        position
      }
      columnHeaders {
        columnName
        columnDisplayType
        columnDisplayName
        columnType
        sectionId
        columnCode
        columnLength
        columnValues {
          value
          id
        }
        isColumnNullable
        isColumnPrimaryKey
        regex
      }
      data
  }`
  let queryString = ''
  stepIds.forEach((stepId, index) => {
    queryString += `data${index}:getStepDetails (stepId: ${stepId} clientId: ${clientId}) {
      ...query
    }`
  })
  return requestWithAuth({
    data: {
      query: `{
        ${queryString}
      }
      ${fragment}
      `
    }
  })
}

const getMedias = (clientId) => {
  return requestWithAuth({
    data: {
      query: `
      {
        getMedias(clientId: ${clientId}){
          id,
          filename
        }
      }
      `
    }
  })
}

const registerPersonalClient = (data) => {
  return requestNoAuth({
    data: {
      query: `
        mutation {
          registerUnauthenticatedUser(input: {
            firstName: "${data.firstName}"
            lastName: "${data.lastName}"
            password: "${data.password}"
            email: "${data.email}"
            termsAccepted: ${data.consentToAllDocuments}
          })
        }
      `
    }
  })
}

const registerEntityClient = (data) => {
  return requestNoAuth({
    data: {
      query: `
        mutation {
          registerUnverifiedOrgUser(input: {
            firstName: "${data.firstName}"
            lastName: "${data.lastName}"
            password: "${data.password}"
            email: "${data.email}"
            termsAccepted: ${data.consentToAllDocuments}
          })
        }
      `
    }
  })
}

const createClient = (appUserId, fullname) => {
  return requestWithAuth({
    data: {
      query: `
      mutation {
        registerOnBoardingClient(appUserId:${appUserId} input:{
          fullname: "${fullname}"
        })
      }
      `
    }
  })
}

const getClientAddressTypes = () => {
  return requestWithAuth({
    data: {
      query: `
        {
          getClientAddressTypes { id label }
        }
      `
    }
  })
}

const getCountries = () => {
  return requestWithAuth({
    data: {
      query: `
      {
        getCountries{
          id
          label
          identifier
        }
      }
      `
    }
  })
}

const getStates = (countryId) => {
  return requestWithAuth({
    data: {
      query: `
        {
          getStates(countryId: ${countryId}){
            id
            label
            identifier
          }
        }
      `
    }
  })
}

const updateClient = (data) => {
  let clientAddresses = ''

  data.forEach((item, index, array) => {
    const address = `{
      addressTypeId: ${item.addressTypeId ? `"${item.addressTypeId}"` : null},
      addressLine1: ${item.addressLine1 ? `"${item.addressLine1}"` : null}
      addressLine2: ${item.addressLine2 ? `"${item.addressLine2}"` : null}
      countryId: ${item.countryId ? `"${item.countryId}"` : null}
      stateId: ${item.stateId ? `"${item.stateId}"` : null}
      postalCode: ${item.postalCode ? `"${item.postalCode}"` : null}
      city: ${item.city ? `"${item.city}"` : null}
    }` + (array.length - 1 > index ? ',' : '')
    clientAddresses += address
  })

  const payload = `addresses: [${clientAddresses}]`

  return requestWithAuth({
    data: {
      query: `
        mutation {
          updateUnverifiedClient(input: {
            ${payload}
          })
        }
      `
    }
  })
}

const getOnboardingStatus = (clientId) => {
  return requestWithAuth({
    data: {
      query: `
      {
        getOnBoardingStatus(clientId: ${clientId}) {
          onBoardingStatus
          nextStep
        }
      }
      `
    }
  })
}

const finishStaticStep = (stepId, clientId) => {
  return requestWithAuth({
    data: {
      query: `
        mutation {
          updateWorkFlowStatusForStaticTable (stepId: ${stepId}, clientId: ${clientId})
        }
      `
    }
  })
}

const addStepData = async ({ stepId, stepPosition, clientId, data, resourcePath, originalData }, mutationName = 'createUnverifiedOnBoardingStep') => {
  if (resourcePath === 'SetCompanyName' || stepPosition === 3) {
    const companyName = originalData.company_legal_name

    if (companyName) {
      await requestWithAuth({
        data: {
          query: `
          mutation{
            updateUnverifiedClient(input:{
              fullName: "${companyName}"
            })
          }
          `
        }
      })
    }
  }
  return requestWithAuth({
    data: {
      query: `
        mutation {
          ${mutationName} (input: {
            stepId: ${stepId}
            clientId: ${clientId}
            requestData: ${data}
          }) {
            changes
          }
        }
      `
    }
  })
}

const updateStepData = async ({ stepId, stepPosition, clientId, data, resourcePath, originalData }) => {
  return addStepData({ stepId, stepPosition, clientId, data, resourcePath, originalData }, 'updateUnverifiedOnBoardingStep')
}

const deleteRow = (rowId, stepId, clientId) => {
  return requestWithAuth({
    data: {
      query: `
        mutation {
          deleteUnverifiedOnBoardingStepData(
            input: {
              stepId: ${stepId}
              clientId: ${clientId}
              requestData: {
                id: ${rowId}
              }
            }
          ) {
            changes
          }
        }
      `
    }
  })
}

const uploadClientDocument = ({ clientId, formData }) => {
  const data = new FormData()
  data.append('name', formData.name)
  data.append('file', formData.file)

  return requestRestApi({
    method: 'post',
    url: `/clients/${clientId}/documents`,
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 32000,
    data
  })
}

const confirmOnboarding = () => {
  return requestWithAuth({
    data: {
      query: `
        mutation {
          confirmOnboarding
        }
      `
    }
  })
}

const getDocumentTypes = () => {
  return requestWithAuth({
    data: {
      query: `
      {
        getClientIdentifierDocumentTypes {
          id
          label
          identifier
          position
        }
      }
      `
    }
  })
}

export {
  registerEntityClient,
  registerPersonalClient,
  getConfiguration,
  getStepConfiguration,
  getStepConfigurations,
  getOnboardingStatus,
  finishStaticStep,
  addStepData,
  updateStepData,
  uploadClientDocument,
  deleteRow,
  createClient,
  getClientAddressTypes,
  getCountries,
  getStates,
  updateClient,
  getMedias,
  getDocumentTypes,
  confirmOnboarding
}
