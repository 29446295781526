import { withAuth } from '@/api/request.js'

const request = withAuth()

export default ({ employeeId }) => {
  return request({
    data: {
      query: `
        {
          getSubsidiaryAccount(subAccountId: ${employeeId}) {
            id
            accountNo
            availableBalance
            savingsProductName
            mobileNo
            designation
            client {
              id
              firstName
              lastName
              identifiers {
                documentKey
                documentType {
                  name
                }
              }
              statusData {
                id
                code
                value
              }
              addresses {
                addressLine1
                addressLine2
                postalCode
                city
                countryName
                stateName
                addressType
              }
              dateOfBirth
            }
            parentAccountId
            remainingAllocatedFunds
            savingsAmountOnHold
            currency
            savingsProductId
            status
            user {
              id
              firstname
              lastname
            }
          }
        }
      `
    }
  })
}
