import axios from 'axios'
import store from '@/store'

const baseURL = `${process.env.VUE_APP_ZENDESK_BASE_URL}/api/v2/search.json`
const token = btoa(`${process.env.VUE_APP_ZENDESK_ADMIN}/token:${process.env.VUE_APP_ZENDESK_API_TOKEN}`)

export const listTickets = async (userEmail) => {
  const params = encodeURI(`requester:${userEmail} type:ticket`)
  const searchURL = `${baseURL}?query=${params}`

  const url = `https://corsproxy.io/?${searchURL}`
  try {
    const { data: { results: tickets } } = await axios.get(url, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Basic ${token}`
      }
    })
    return tickets
  } catch (error) {
    store.dispatch('support/checkZendeskAuthenticate', { isZendeskUnauthenicated: true })
  }
}
