import Vue from 'vue'

let unsubscribePermissionsWatcher

// "v-has-permissions" directive, string or array of strings can be passed, e.g. v-has-permissions = "['PERMISSION']" or v-has-permissions = "'PERMISSION'"
Vue.directive('hasPermissions', {
  inserted (el, binding, vnode) {
    const store = vnode.context.$store
    const permissions = binding.value
    if (!store || !permissions) return
    const checkPermission = () => {
      if (!store.getters['user/userHasPermissions'](permissions)) {
        el.parentNode?.removeChild(el)
      }
    }
    checkPermission()
    // The part below is needed to update element's presence in case vuex permissions are updated dynamically
    unsubscribePermissionsWatcher = store.subscribe((mutation) => {
      if (mutation.type === 'user/update') {
        checkPermission()
      }
    })
  },
  unbind () {
    unsubscribePermissionsWatcher()
  }
})
