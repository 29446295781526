import i18n from '@/plugins/i18n.js'

const clientPublicDomain = 'https://public.tokenizer-s.mbanq.cloud'

const termAndConditions = [
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.termsOfService'),
    link: `${clientPublicDomain}/terms-of-service.pdf`
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.privacyPolicy'),
    link: `${clientPublicDomain}/privacy-policy.pdf`
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.accountCardAgreement'),
    link: `${clientPublicDomain}/business-account-and-cardholder-agreement.pdf`
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.electronicCommunicationsAgreement'),
    link: `${clientPublicDomain}/electronic-communication-consent.pdf`
  }
]

export default termAndConditions
