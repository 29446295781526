const recipient = `
  fragment recipient on Beneficiary {
    name
    email
    mobile
    creditor {
      name
      identifier
      city
      country
      postalCode
      stateOrProvince
      address
      accountType
      agent {
        identifier
        name
        address
        city
        country
        stateOrProvince
        postalCode
      }
    }
    paymentType {
      name
    }
  }
`

export {
  recipient
}
