import { withAuth, restApi } from '@/api/request.js'
import { generateAgentInput } from '@/api/views/recipients/createRecipient'

const request = withAuth()
const requestRestApi = restApi()

const getTransfers = ({ limit } = {}) => {
  return request({
    data: {
      query: `
        {
          Transfers (
            ${limit ? `page:{start: 1, limit: ${limit}}` : ''}
            where: {
              status: {EQ: EXECUTION_SUCCESS}
            }
          ) {
            select {
              creditor(where: {
                AND: {name: {NOT_NULL:true} identifier: {NOT_NULL: true}}
              }) {
                name
                identifier
              }
              id(orderBy:DESC)
              amount
              valueDate
              paymentType {
                name
              }
            }
          }
        }
      `
    }
  })
}

const draft = (payload) => {
  const {
    creditor,
    debtor,
    currency,
    amount,
    subject,
    fullName,
    paymentType
  } = payload
  let creditorIdentifier

  if (paymentType.value === 'INTERNAL') {
    creditorIdentifier = `ACCOUNT:${creditor.accountNo}`
  } else {
    creditorIdentifier = `${paymentType.value}://${creditor.sortCode}/${creditor.accountNo}`
  }
  const agent = generateAgentInput(paymentType.value, creditor)
  return request({
    data: {
      query: `
        mutation {
          createAndSubmitTransfer(input: {
            type: "CREDIT"
            paymentType: "${paymentType.value}"
            currency: "${currency}"
            amount: ${+amount}
            dateFormat: "YYYY-MM-DD"
            reference: ${JSON.stringify(subject)}
            debtor: {
              identifier: "ACCOUNT:${debtor.accountNumber}"
            }
            creditor: {
              ${creditor?.accountType ? `accountType: ${creditor.accountType}` : ''}
              identifier: "${creditorIdentifier}"
              name: "${fullName}"
              ${creditor?.address ? `address: ${JSON.stringify(creditor.address)}` : ''}
              ${creditor?.country ? `country: "${creditor.country}"` : ''}
              ${creditor?.city ? `city: "${creditor.city}"` : ''}
              ${creditor?.postalCode ? `postalCode: "${creditor.postalCode}"` : ''}
              ${creditor?.state ? `stateOrProvince: "${creditor.state}"` : ''}
              ${agent}
            }
          }
          ) {id}
        }
      `
    }
  })
}

const uploadTransferDocument = ({ transferId, formData }) => {
  const data = new FormData()
  data.append('name', formData.name)
  data.append('file', formData.file)

  return requestRestApi({
    method: 'post',
    url: `/self/transfer/${transferId}/documents`,
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 32000,
    data
  })
}

const downloadTransferDocument = (transferId, documentId) => {
  return requestRestApi({
    method: 'get',
    url: `/self/transfer/${transferId}/documents/${documentId}/attachment`,
    responseType: 'blob',
  })
}


export {
  draft,
  getTransfers,
  uploadTransferDocument,
  downloadTransferDocument
}
