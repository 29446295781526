<template>
  <div class="w-100">
    <v-divider class="my-2" />
    <v-btn
      small
      text
      class="pl-0 w-100 justify-start"
      :loading="loading"
      @click.stop="download"
    >
      <v-icon
        class="mr-2"
      >
        file_download
      </v-icon>
      {{ $t('components.accounts.documents.downloadWireDetails') }}
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'More',
  accountNumber: {
    type: String,
    required: true
  },
  props: {
    accountNumber: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async download () {
      this.loading = true
      const reportType = 'Wire Details'
      const accountId = this.accountNumber
      try {
        await this.$store.dispatch('accounts/download', {
          reportType,
          accountId
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
