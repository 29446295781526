import Vue from 'vue'
import InputFacade from 'vue-input-facade'

export const option = {
  // Copied from https://github.com/RonaldJerez/vue-input-facade/blob/master/src/tokens.js
  tokens: {
    '#': { pattern: /\d/ },
    X: { pattern: /[0-9a-z]/i },
    S: { pattern: /[a-z]/i },
    A: { pattern: /[a-z]/i, transform: (v) => v.toLocaleUpperCase() },
    a: { pattern: /[a-z]/i, transform: (v) => v.toLocaleLowerCase() },
    '\\': { escape: true },
    '?': { optional: true },
    '*': { repeat: true },
    '|': { pipe: true },
    // Extending the plugin for input token in form of a string with number and uppercase letters
    U: { pattern: /[0-9a-z]/i, transform: (v) => v.toLocaleUpperCase() }
  }
}

Vue.use(InputFacade, option)
