import path from 'path-browserify'

const client = process.env.VUE_APP_CLIENT || 'mbanq'

const getAppClient = () => client

const getClientSpecificFile = (file, dirName = '') => {
  const filePath = path.join(client, dirName, file)

  return require('@/clientSpecific/' + filePath)
}

export {
  getAppClient,
  getClientSpecificFile
}
