import {
  withAuth
} from '@/api/request.js'

const request = withAuth()

export const getAccountNumberFormat = () => {
  return request({
    data: {
      query: `
        {
          AccountNumberFormats (where: { accountTypeEnum: { EQ: 3 } }) {
            select {
              customPrefix
              accountNumberLength
            }
          }
        }
      `
    }
  })
}
