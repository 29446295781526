import i18n from '@/plugins/i18n.js'

const termAndConditions = [
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.termsOfService'),
    link: 'https://public.tokenizer-s.mbanq.cloud/terms-of-service.pdf'
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.privacyPolicy'),
    link: 'https://public.tokenizer-s.mbanq.cloud/privacy-policy.pdf'
  },
  {
    displayText: i18n.t('components.about.contactUs'),
    link: 'https://tokenizerplatform.zendesk.com/hc/en-us'
  }
]

export default termAndConditions
