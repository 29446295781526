import {
  draft as createTransfer,
} from '@/api/views/transfers.js'
import enabled from '@/config/enabled'
import { uploadTransferDocument as uploadTransferDocumentApi } from '@/api/views/transfers'

const transferOption = {
  normal: [
    { value: 'ACH', name: 'ACH Transfer', description: 'Usually money will hit your account in 3 days' },
    { value: 'SWIFT', name: 'SWIFT Transfer', description: '' }
  ].filter(
    paymentType => enabled.find(feature => feature.includes('payment-types')).includes(paymentType.value.toLowerCase())
  ),
  instant: [
    { value: 'INTERNAL', name: 'Internal transfer', description: 'Instant transfer within the same bank' },
    { value: 'WIRE', name: 'Fedwire Transfer', description: 'Nearly instant transfer, the money should arrive on the same day' }
  ].filter(
    paymentType => enabled.find(feature => feature.includes('payment-types')).includes(paymentType.value.toLowerCase())
  )
}

const getDefaultState = {
  errors: undefined,
  transferTypes: {
    domestic: {
      recipientNameLabel: 'components.transfers.new.transferTypes.recipientName',
      accountNoLabel: 'components.transfers.new.transferTypes.accountNo',
      bankCodeLabel: 'components.transfers.new.transferTypes.bankCode',
      accountNoMask: 'N### ####',
      bankCodeMask: ''
    },
    sepa: {
      recipientNameLabel: 'components.transfers.new.transferTypes.recipientName',
      accountNoLabel: 'components.transfers.new.transferTypes.iban',
      bankCodeLabel: 'components.transfers.new.transferTypes.bic',
      accountNoMask: 'AA## NNNN #### #### #### #### NNNN NNNN',
      bankCodeMask: ''
    },
    email: {
      recipientNameLabel: 'components.transfers.new.transferTypes.email',
      bankCodeLabel: 'components.transfers.new.transferTypes.recipientName',
      bankCodeMask: ''
    }
  },
  newTransfer: {
    amount: '',
    subject: '',
    remainingBalance: '',
    paymentType: transferOption.normal[0]
  },
  transferFiles: [],
  isCompletedStep: {
    recipientAccountInfo: false,
    transferAccountInfo: false,
    debitAccountInfo: false,
    transferTypeInfo: false
  },
  transferStep: 'SearchList',
  inEdit: false,
  disableEditFirst2Step: false,
  isFromBeneficiaryOrRecipient: false,
  previousStep: { panelName: '' },
  transferOption
}

const state = getDefaultState

const getters = {
  errors: state => state.errors,
  getTransfer: state => state.newTransfer,
  inEdit: state => state.inEdit,
  disableEditFirst2Step: state => state.disableEditFirst2Step,
  newTransfer: state => state.newTransfer,
  navigationStep: (state) => state.transferStep,
  getAllTransferOption: (state) => {
    return [
      ...state.transferOption.normal,
      ...state.transferOption.instant
    ]
  },
  getTransferFiles: (state) => {
    return [...state.transferFiles]
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState)
  },
  setErrors (state, errors) {
    state.errors = errors
  },
  resetErrors (state) {
    state.errors = undefined
  },
  saveRecipient (state, { data, to }) {
    state.newTransfer = { ...state.newTransfer, ...data }
    state.transferStep = to
    state.disableEditFirst2Step = false
  },
  saveTransferFiles (state, transferFiles) {
    state.transferFiles = [...transferFiles]
  },
  setNavigation (state, payload) {
    state.transferStep = payload
  },
  updateRecipient (state, { data, step }) {
    state.newTransfer = { ...state.newTransfer, ...data }
    state.transferStep = state.previousStep.panelName
    state.isCompletedStep[step] = true
    state.inEdit = false
  },
  discardTransfer (state) {
    state.transferStep = 'SearchList'
    state.isCompletedStep = { ...state.isCompletedStep, ...getDefaultState.isCompletedStep }
    state.previousStep = { ...state.previousStep, ...getDefaultState.previousStep }
    state.inEdit = false
    state.newTransfer = getDefaultState.newTransfer
    state.disableEditFirst2Step = false
    state.transferFiles = []
  },
  updateNewTransferState (state, { data }) {
    state.newTransfer = { ...state.newTransfer, ...data }
  },
  setCompleteStep (state, { step, disabledRecipientDetailEditButton }) {
    state.isCompletedStep[step] = true
    state.disableEditFirst2Step = !!disabledRecipientDetailEditButton && state.isFromBeneficiaryOrRecipient
  },
  removeCompleteStep (state, { step }) {
    state.isCompletedStep[step] = false
  },
  editPanel (state, { panelName, step }) {
    state.previousStep = { ...state.previousStep, ...{ panelName: state.transferStep } }
    state.transferStep = panelName
    state.isCompletedStep[step] = false
    state.inEdit = true
  },
  transferDetailStep (state) {
    state.disableEditFirst2Step = true
    state.isCompletedStep.transferTypeInfo = true
    state.isCompletedStep.recipientAccountInfo = true
  },
  disabledRecipientDetailEditButton (state, { disabled }) {
    state.isFromBeneficiaryOrRecipient = disabled
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  disabledRecipientDetailEditButton ({ commit }, payload) {
    commit('disabledRecipientDetailEditButton', payload)
  },
  saveRecipient ({ commit }, payload) {
    commit('saveRecipient', payload)
  },
  saveTransferFiles({commit}, payload) {
    commit('saveTransferFiles', payload)
  },
  updateRecipient ({ commit }, payload) {
    commit('updateRecipient', payload)
    commit('resetErrors')
  },
  updateNewTransferState ({ commit }, payload) {
    commit('updateNewTransferState', payload)
    commit('resetErrors')
  },
  async createTransfer ({ commit }, recipient) {
    try {
      // Convert subject to array
      recipient = { ...recipient, subject: [`${recipient.subject}`] }
      const result = await createTransfer(recipient)

      if (result.data.errors) {
        const errors = extractErrors(result.data.errors)
        commit('setErrors', errors)
        window.scrollTo({ top: 50, behavior: 'smooth' })
        throw result.data.errors.map(err => ({ routingNumberErr: [err.message] }))
      }

      return result
    } catch (error) {
      if (error[0].routingNumberErr) throw error[0].routingNumberErr
      throw error?.errors[0]?.extensions?.errors[0]?.defaultUserMessage.split(':') || error.errors[0].message.split(':')
    }
  },
  async uploadTransferDocument ({state, commit}, transferId) {

    const nonEmptyTransferFiles = state.transferFiles.filter (
      file => !!file.name
    )

    if (!nonEmptyTransferFiles.length) return

    const uploadFilePromises = nonEmptyTransferFiles.map(
      file => {
        const formData = {
          file: file,
          name: file.name,
        }

        return (
          uploadTransferDocumentApi({
            transferId, formData
          })
        )
      }
    )
    await Promise.all(uploadFilePromises)
    commit('saveTransferFiles', [])
  },
  discardTransfer ({ commit }) {
    commit('resetErrors')
    commit('discardTransfer')
  },
  setNavigation ({ commit }, payload) {
    commit('setNavigation', payload)
  },
  setCompleteStep ({ commit }, payload) {
    commit('setCompleteStep', payload)
  },
  removeCompleteStep ({ commit }, payload) {
    commit('removeCompleteStep', payload)
  },
  editPanel ({ commit }, payload) {
    commit('editPanel', payload)
  },
  transferDetailStep ({ commit }) {
    commit('transferDetailStep')
  }
}

const extractErrors = (resultErrors) => {
  const extracted = resultErrors[0].extensions.errors?.length
    ? resultErrors.map(({ extensions: { errors } }) => {
      return errors.map(({ parameterName, defaultUserMessage }) => {
        return {
          field: parameterName,
          message: defaultUserMessage
        }
      })
    })
    : resultErrors[0].message
  const errorz = Array.isArray(extracted)
    ? extracted[0]
    : extracted
  return errorz
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
