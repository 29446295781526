import i18n from '@/plugins/i18n.js'

const termAndConditions = [
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.termsOfService'),
    link: 'https://cheqly.com/us/terms-of-service/'
  },
  {
    displayText: i18n.t('components.registration.customerCreation.fields.consentToAllDocuments.privacyPolicy'),
    link: 'https://cheqly.com/us/privacy-policy/'
  },
  {
    displayText: i18n.t('components.about.contactUs'),
    link: 'https://cheqly.com/us/contact/'
  }
]

export default termAndConditions
