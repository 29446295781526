import { withAuth } from '@/api/request.js'
import store from '@/store/index.js'
import {
  currentAccount,
  savingsTransaction,
  subsidiaryAccountTransaction,
  card
} from '@/api/fragments/index.js'

const request = withAuth()

export default ({ savingsIds }) => {

  const isEmployee = store.getters['user/isEmployee']

  const txGql = (accountId) => {
    const accountTxs = store.getters['transactions/list']([accountId], 'savings')
    const latestAccountTx = accountTxs[0]

    if(isEmployee) {
      return `
        latestTxSavings${accountId} : getSubsidiaryAccountTransactions (
          page: {start: 1 limit: 10}
        ) {
          select {
            id
            ...subsidiaryAccountTransaction
          }
        }
      `
    }

    return `
      latestTxSavings${accountId} : SavingsAccountTransactions (
        where: {
          reversed: {EQ: false}
          id: {GT: ${latestAccountTx?.id || 0} }
          account : {id: {EQ: ${accountId}}}
        }
        page: {start: 1 limit: 10}
      ) {
        select {
          id (orderBy: DESC)
          ...savingsTransaction
        }
      }
    `
  }

  return request({
    data: {
      query: `
        {
          AuthorizeTransactions (where: {active: {EQ: true}}){
            select{
              id
              hold: active
              account{
                id
                currency {
                  code
                }
              }
              transfer {
                id
                type
                creditor {
                  identifier
                }
                debtor {
                  identifier
                }
                paymentType {
                  name
                }
                reference
              }
              createdAt(orderBy: DESC)
              amount
              pendingAmount
              cardAuthorization {
                transactionType
                merchant
              }
            }
          }
          SavingsAccounts {
            select {
              ...currentAccount
            }
          }
          ${savingsIds.map(id => txGql(id)).toString()}
          Cards (where: {status:{NIN:[TERMINATED, REJECTED]}}) {
            select {
              ...card
            }
          }
        }
        ${currentAccount}
        ${isEmployee ? subsidiaryAccountTransaction : savingsTransaction}
        ${card}
      `
    }
  })
}
