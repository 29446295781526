import { getAppClient } from '@/utils/clientSpecificHelpers'

const generateAccountStatementFilename = (accountNumber) => {
  const clientName = getAppClient()
  return `${clientName}-Account-Statement-${accountNumber}.pdf`
}

export {
  generateAccountStatementFilename
}
