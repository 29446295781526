import { withAuth } from '@/api/request.js'
import { saveDataAsFile } from '@/utils/helpers'
import { generateAccountStatementFilename } from '@/utils/fileName'

const request = withAuth()

const getDefaultState = () => {
  return {
    communications: [],
    totalCommunications: 0
  }
}

const state = getDefaultState()

const getters = {
  id: (state) => (id) => {
    return state.communications.find(message => message.id === id)
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  updateCommunications (state, payload) {
    const { Medias: { select, total } } = payload
    state.communications = select
    state.totalCommunications = total
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },

  async download ({ getters }, { documentId, accountNo }) {
    const { entityId, id } = getters.id(documentId)
    try {
      const downloadStatement = (accountId, documentId) => {
        return request({
          method: 'get',
          baseURL: `${process.env.VUE_APP_API_URL}/v1/self/savings/${accountId}/documents/${documentId}/attachment`,
          responseType: 'blob'
        })
      }
      const { data } = await downloadStatement(entityId, id)
      const filename = generateAccountStatementFilename(accountNo || entityId)
      saveDataAsFile(data, filename)
    } catch (error) {
      Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
