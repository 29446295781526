import { withAuth } from '@/api/request.js'

const request = withAuth()

const getCardProducts = () => {
  return request({
    data: {
      query: `
        {
          CardProducts {
            select {
              id
              name
              cardType
              network
              yearExpire
              virtual
              currency {
                code
              }
            }
          }
        }
      `
    }
  })
}

const orderEmployeeCard = (payload) => {
  return request({
    data: {
      query: `mutation {
        orderCard(
          input: {
            productId: "${payload.productId}"
            savingsAccountId: "${payload.savingsAccountId}"
            userId: "${payload.userId}"
          }
        ) {
          id
          status
          }
      }`
    }
  })
}

const getEmployeeCards = ({ subAccountId }) => {
  return request({
    data: {
      query: `
        {
          getSubsidiaryAccountCards(subAccountId: ${subAccountId}) {
           id
           network
           productName
           primaryAccountNumber
           status
           expiresOn
           posPaymentEnabled
           onlinePaymentEnabled
           contactlessPaymentEnabled
           atmWithdrawalsEnabled
           internationalPaymentsEnabled
           blockedCountries
           physicalCardActivated
          }
        }
      `
    }
  })
}

const getEmployeeCardVelocityRules = ({ cardId }) => {
  return request({
    data: {
      query: `
        {
          VelocityRules(cardId: ${cardId}) {
            controls
            expiryDate
            timeUnit
            timePeriod
            type
            value
          }
        }
      `
    }
  })
}

export {
  orderEmployeeCard,
  getCardProducts,
  getEmployeeCards,
  getEmployeeCardVelocityRules
}
