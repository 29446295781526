import { withAuth } from '@/api/request.js'

const request = withAuth()

export default ({ employeeId, limit, offset }) => {
  return request({
    data: {
      query: `
        {
          getSubsidiaryAccountTransactions(where: { subAccountId: ${employeeId} } page: { limit: ${limit}, start: ${offset} }) {
            total
            select {
              id
              amount
              typeOf
              createdAt
              paymentDetail {
                reference
                cardAuthorization {
                  id
                  merchant
                  amountDetails {
                    cashBackAmount
                    exchangeRate
                    feeAmount
                    localCurrency
                    localCurrencyAmount
                  }
                }
              }
              account {
                id
                currency {
                  code
                }
              }
            }
          }
        }
      `
    }
  })
}
