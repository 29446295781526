const getDefaultState = () => {
  return {
    name: undefined,
    emailAddress: undefined,
    address: undefined,
    about: undefined
  }
}

const state = getDefaultState()

const getters = {
  about: (state) => state.about,
  address: (state) => state.address
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  update (state, payload) {
    Object.assign(state, payload)
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  update ({ commit }, organization) {
    commit('update', organization)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
