export default {
  required: 'Field is required',
  generic: {
    required: 'Field is required',
    alphanum: 'Field must be alphanumeric',
    numeric: 'Only numbers are allowed',
    numericLetters: 'Only numbers and letters are allowed',
    noSpaces: 'No spaces are allowed',
    minLength: 'Must have at least {length} characters',
    maxLength: 'Must not exceed {length} characters',
    minMaxOrEmpty: 'Need to be between {minLength} - {maxLength} characters',
    exactLength: 'Must be {length} characters'
  },
  email: {
    required: 'E-mail is required',
    valid: 'E-mail must be valid'
  },
  otp: {
    required: 'OTP is required',
    valid: 'OTP must be valid',
    length: 'OTP must have exactly 5 characters'
  },
  password: {
    required: 'Password is required',
    length: 'Password must have at least 8 characters',
    lowercase: 'Must have lowercase character',
    uppercase: 'Must have uppercase character',
    number: 'Must have number',
    symbol: 'Must have symbol'
  },
  tos: {
    required: 'You must agree to continue!'
  },
  names: {
    required: 'Field is required',
    alpha: 'Name must be alphabetic letters'
  },
  city: {
    required: 'Field is required',
    alpha: 'City must be alphabetic letters'
  },
  country: {
    required: 'Field is required',
    supported: 'You have to be in USA to use the product'
  },
  zip: {
    required: 'Field is required',
    length: 'German postal code can only have 5 digits',
    numeric: 'German Postal code must only contain numbers'
  },
  birthDay: {
    required: 'Field is required',
    minAge: 'You need to be at least 18 years old!'
  },
  isUsCitizen: {
    required: 'You may not be US-Citizen to become a customer of Cometum!'
  },
  alphanumField: {
    required: 'Field is required',
    alphanum: 'Field must be alphanumeric'
  },
  mobile: {
    invalid: 'Invalid phone number'
  },
  iban: {
    required: 'Field is required',
    alphanum: 'IBAN must contain uppercase letters and numbers',
    minlength: 'IBAN must have at least 16 characters',
    maxlength: 'IBAN must have at most 34 characters'
  },
  bic: {
    invalid: 'Invalid BIC'
  },
  taxNumber: {
    required: 'Field is required',
    alphanum: 'Field must be alphanumeric',
    minlength: 'Field must have at least 6 characters'
  },
  url: {
    required: 'Field is required',
    valid: 'Invalid URL'
  }
}
